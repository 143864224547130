import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useTickets = ({
	searchParam,
	pageNumber,
	status,
	date,
	updatedAt,
	showAll,
	queueIds,
	withUnreadMessages,
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [contacts, setContacts] = useState([]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async () => {
				try {
					const { data } = await api.get("/tickets", {
						params: {
							searchParam,
							pageNumber,
							status,
							date,
							updatedAt,
							showAll,
							queueIds,
							withUnreadMessages,
						},
					});

					// caso seja paginação, concatenar atendimentos já carregados com os novos
					if (pageNumber > 1) setTickets([...tickets, ...data.tickets]);
					else setTickets(data.tickets);

					setHasMore(data.hasMore);
					setContacts(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
				}
			};
			fetchTickets();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		searchParam,
		pageNumber,
		status,
		date,
		updatedAt,
		showAll,
		queueIds,
		withUnreadMessages,
	]);

	return { tickets, loading, hasMore, contacts, setLoading };
};

export default useTickets;
