import { i18n } from "../../translate/i18n";
import ChatIcon from '@material-ui/icons/Chat';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DoneIcon from '@material-ui/icons/Done';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LabelIcon from '@material-ui/icons/Label';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import NearMeIcon from '@material-ui/icons/NearMe';

export const menuItems = [
  {
    text: i18n.t('menu.list.ticket'),
    to: '/tickets',
    icon: <ChatIcon />
  },
  {
    text: i18n.t('menu.list.contact'),
    to: '/contacts',
    icon: <PeopleIcon />
  },
  {
    text: i18n.t('menu.list.dashboard'),
    to: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    text: i18n.t('menu.list.report.title'),
    icon: <EqualizerIcon />,
    items: [{
      text: i18n.t('menu.list.report.ticketsRelatory'),
      to: '/ticketRelatory',
    },{
      text: i18n.t('menu.list.report.tagsRelatory'),
      to: '/tagRelatory',
    },{
      text: i18n.t('menu.list.report.generalCampaignsRelatory'),
      to: '/generalCampaignsRelatory',
    }]
  },
  {
    text: i18n.t('menu.list.campaign'),
    to: '/campaign',
    icon: <NearMeIcon />
  },
];

export const adminItems = [
  {
    text: i18n.t('menu.list.admin.title'),
    icon: <SettingsIcon />,
    items: [{
      text: i18n.t('menu.list.admin.connection'),
      to: '/connections',
    }, {
      text: i18n.t('menu.list.admin.queue'),
      to: '/queues'
    }, {
      text: i18n.t('menu.list.admin.openingHour'),
      to: '/openingHour',
    }, {
      text: i18n.t('menu.list.admin.quickAnswer'),
      to: '/quickAnswers',
    }, {
      text: i18n.t('menu.list.admin.conclusionMessage'),
      to: '/conclusionMessages',
    }, {
      text: i18n.t('menu.list.admin.tag'),
      to: '/tags',
    }, {
      text: i18n.t('menu.list.admin.user'),
      to: '/users',
    }, {
      text: i18n.t('menu.list.admin.integration'),
      to: '/integrations',
    }]
  }
];