import { Box, Button, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/logo_login.png";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const isMobile = Number(window.innerWidth) < 600 ? true : false;
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        verticalAlign: 'middle',
        padding: '10px',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#448aff',
        backgroundImage: 'linear-gradient(to bottom right, #0b708c, #00695c)',
    },
    form: {
        maxWidth: '800px',
        width: '90vw',
        borderRadius: '10px',
        maxHeight: '600px',
        margin: '0 auto',
        padding: '20px 50px',
        boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
        backgroundColor: '#fff',
        border: '1px solid rgba(0,0,0,.2)',
        position: 'relative',
        marginTop: isMobile ? '10vh' : 'calc((100vh - 600px) / 2)'
    },
    title: {
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '30px',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '10px',
        marginTop: '20px'
    },
    subtitle: {
        fontSize: '1.2rem',
        color: '#757575'
    },
    button: {
        display: 'block',
        width: 'calc(100% - 90px)',
        bottom: '50px',
        position: 'absolute',
        left: '45px',
        height: '40px',
    },
    logo: {
        width: '40px',
        height: '40px',
        marginRight: '10px'
    },
    finishedTitle: {
        fontSize: '1.34em',
        textAlign: 'center',
        marginTop: '175px',
        "& svg": {
            marginRight: '10px',
            width: '50px',
            height: '50px',
            color: '#4caf50'
        }
    }
}));

const BlackListCampaign = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [status, setStatus] = useState(false);

    const addBlackList = async () => {
        setLoading(true);
        const { adminId, campaignContactId } = params;
        try {
            await api.post(`/campaign/addBlackList`, { adminId, campaignContactId });
            setStatus(true);
            toast.success("Inscrição desfeita com sucesso!");
        } catch (err) {
            toastError(err);
            setStatus(false);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.form}>
                <Typography variant="h3" className={classes.title}>
                    <img src={Logo} alt="Conexa Digital" className={classes.logo} />
                    Bloquear campanha
                </Typography>
                {
                    status ?
                        <>
                            <Typography variant="h4" className={classes.finishedTitle} >
                                <CheckCircleOutlineIcon />
                                Bloqueio feito com sucesso!
                            </Typography>
                        </>
                        :
                        <>
                            <Typography variant="body1" className={classes.subtitle}>Clique no botão abaixo para cancelar sua inscrição em nosso sistema e não receber mais campanhas desse número.</Typography>
                            <Button className={classes.button} variant="contained" color="primary" onClick={() => { if (!loading) addBlackList() }} >
                                {
                                    loading ?
                                        <CircularProgress
                                            size={24}
                                            color="#fff"
                                        />
                                        :
                                        'confirmar'
                                }
                            </Button>
                        </>
                }
            </Box>
        </Box>
    );
};

export default BlackListCampaign;