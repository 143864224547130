import { useContext, useEffect, useReducer, useState } from "react";

import { makeStyles } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";

import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import ConfirmationModal from "../../components/ConfirmationModal";
import TagModal from "../../components/Tag/TagModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import { Switch } from "@mui/material";
import socket from "../../hooks/useSocket";
import { Button } from "@material-ui/core";

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((q) => q.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((q) => q.id === tag.id);
    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAGS") {
    const tagId = action.payload;

    const tagIndex = state.findIndex((q) => q.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '395px',
    margin: '20px 10px!important',
		padding: '10px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const Tags = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [selectedTags, setSelectedTags] = useState(null);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [deletingTags, setDeletingTags] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);

  const adminId = user.adminId;

  const [getUsers, setGetUsers] = useState([]);
  const [getCreatedBy, setSelectedUserFilter] = useState("");


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, getCreatedBy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/usersList/");
          setGetUsers(data)
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTags = async () => {
        try {
          const { data } = await api.get("/tags/", {
            params: { searchParam, pageNumber, getCreatedBy },
          });
          dispatch({ type: "LOAD_TAGS", payload: data.tags });
          setHasMore(!!data?.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, getCreatedBy]);

  useEffect(() => {
    socket.on(`tags-${adminId}`, (data) => {

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tag });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_TAGS",
          payload: +data.tagId,
        });
      }
    });

    return () => {};
  }, []);

  const handleChange = async (event) => {
    if (event.target.value === "") {
      setSelectedUserFilter("");
    } else {
      setSelectedUserFilter(event.target.value);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenTagModal = () => {
    setSelectedTags(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTags(null);
    setTagModalOpen(false);
  };

  const handleEditTags = (tag) => {
    setSelectedTags(tag);
    setTagModalOpen(true);
  };

  const handleDisableTags = async (tagData) => {
    try {
      tagData = { ...tagData, status: !tagData.status };
      await api.put(`/tags/${tagData.id}`, tagData);
      toast.success(i18n.t("tagModal.success"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTags(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingTags &&
          `${i18n.t("tags.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        aria-labelledby="form-dialog-title"
        tagId={selectedTags && selectedTags.id}
      ></TagModal>
      <div class='row gy-5 gx-xl-8'
        className={classes.modalStyle}
        onScroll={handleScroll}>
        <div className='col-xl-15'>
          <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t("tags.title")}</span>
                {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
              </h3>
              <div
                className='card-toolbar '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenTagModal}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  {i18n.t("tags.buttons.add")}
                </Button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>{i18n.t("tags.table.name")}</th>
                      <th className='min-w-120px'>{i18n.t("tags.table.color")}</th>
                      <th className='min-w-120px'>{i18n.t("tags.table.status")}</th>
                      <th className='min-w-120px'>{i18n.t("tags.table.updatedBy")}</th>
                      <th className='min-w-100px text-end'>{i18n.t("tags.table.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {tags.map((tag) => (
                        <>
                          <tr >
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {tag.name}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                <div style={{ padding: "10px", backgroundColor: tag.color, borderRadius: '30px', maxWidth: '50px' }} />
                              </a>
                            </td>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <Switch
                                    checked={tag.status}
                                    onChange={() => handleDisableTags(tag)}
                                    color="primary"
                                    name={`status${tag.status}`}
                                    key={`status${tag.status}`}
                                    inputProps={{ 'aria-label': 'handle status' }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {tag?.['User.name']}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>

                                <button
                                  title={i18n.t("buttons.edit")}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleEditTags(tag)}
                                >
                                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </button>

                                {/* <button
                                  title={
                                    tag?.status ? "desabilitar" : "habilitar"
                                  }
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleDisableTags(tag)}
                                >
                                  {tag?.status ? <BlockIcon /> : <CheckCircleIcon />}
                                </button> */}

                              </div>
                            </td>
                          </tr>
                        </>
                      ))}

                    </>
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Tags;
