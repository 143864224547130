import { makeStyles, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import GraphRelatory from '../../components/WidgetsRelatory/widgetRelatoryTickets';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Chart from "./chart/donutsChart";
import Table from "./table";

//Loading contents 
const useStyles = makeStyles(theme => ({
	container: {
		margin: '0!important',
		padding: '0!important'
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 380,
	},
	button: {
		padding: "7px",
		float: "right",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
		"& svg": {
			color: theme.palette.primary.contrastText,
			marginRight: '5px'
		},
	  },
}));

const RelatoryTicket = () => {
	const classes = useStyles();
	var heightChartDefault = '100%'
	const [contentRelatory, setContentRelatory] = useState([]);
	const [usersRelatory, setUsersRelatory] = useState([]);
	const [searchStatus, setSearchStatus] = useState("");
	const [searchParams, setSearchParams] = useState("");
	const [userRelatory, setuserRelatory] = useState("");
	const [searchDate, setSearchDate] = useState({ start: '', end: '' });
	const [loading, setLoading] = useState(true);
	const [loadingContent, setLoadingContent] = useState(false);
	var initialContent = { start: '', end: '', status: '' };
	const [values, setValues] = useState(initialContent);
	var defaultResolution = { filter: 'col-xl-2', donuts: 'col-xl-4', table: 'col-xl-6' };
	const [bootstrapResolution, setBootstrapResolution] = useState(defaultResolution);
	const [heightChart, setHeightChart] = useState(heightChartDefault);
	const [dateTime, setDateTime] = useState({ start: '', end: '' });

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				setLoadingContent(true)
				try {

					let params = { searchStatus, searchDate, userRelatory, searchParams }
					if (userRelatory === "chatbot") {

					}else{
						
					}
					const { data } = await api.get("/relatory-tickets/", { params });
					if (data.length > 0) {
						setContentRelatory(data[0])
					}
				} catch (err) {
					toastError(err);
				}
				setLoadingContent(false)
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchStatus, searchDate, userRelatory, searchParams]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("/usersList/");
					if (data.length > 0) {
						setUsersRelatory(data)
					}
				} catch (err) {
					toastError(err);
				}
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, []);


	function resize() {
		if (Number(window.innerWidth) <= 1800) {
			var newValue = { filter: 'col-xl-3', donuts: 'col-xl-4', table: 'col-xl-5' };
			setBootstrapResolution(newValue);
		} else {
			setBootstrapResolution(defaultResolution);
		}
		if (Number(window.innerWidth) <= 1200) {
			setHeightChart(heightChartDefault)
		} else {
			setHeightChart('95%')
		}
	}

	window.onresize = resize;
	function openDatePicker(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}

	const filterData = () => {
		var startDate = document.querySelector('#start_date').value
		var endDate = document.querySelector('#end_date').value
		var statusTicket = document.querySelector('#status_tickets').value
		var userRelatory = document.querySelector('#user_tickets').value
		var filterRelatory = document.querySelector('#filter_tickets').value
		var content = { start: startDate, end: endDate, status: statusTicket, user: userRelatory }
		setSearchStatus(statusTicket);
		setSearchDate({ start: startDate, end: endDate });
		setuserRelatory(userRelatory);
		setSearchParams(filterRelatory);
		setValues(content);
	}
	useEffect(() => {
		setTimeout(() => {
			resize();
			setLoading(false)
		}, 1000);
	}, []);



	const formatDate1Week = () => {
		var uma_semana = new Date();
		uma_semana.setDate(uma_semana.getDate() - 8);
		return new Date(uma_semana).toISOString().split("T")[0]
	}

	return (
		<MainContainer>
			<div className={`row g-5 g-xl-8 ${classes.container}`} >
				{loading ? (
					<>

					</>
				) : (
					<>
						<div className={`${bootstrapResolution.table}`}>
							{loadingContent ? (
								<loadingTable
									title="Relatório (gráfico por datas)"
									className='card-xl-stretch mb-xl-8'
									style={{marginTop: '0px!important'}}
									chartColor='info'
								/>
							) : (
								(contentRelatory.relatory_all_days &&
									<>
										<GraphRelatory
											title="Relatório (gráfico por datas)"
											className='card-xl-stretch mb-xl-8'
											style={{marginTop: '0px!important'}}
											chartColor='info'
											chartHeight='300px'
											all_relatory={contentRelatory.relatory_all_days}
										/>
									</>
								)
							)}
						</div>
						<div className={`${bootstrapResolution.donuts} col-lg-6 col-md-6`} style={{marginTop: '0px!important'}} >
							<div className={`card ${'card-xl-stretch mb-xl-8'}`} style={{ height: `${heightChart}` }}>
								{loadingContent ? (
									<Chart
										title="Gráfico do relatório"
										status={contentRelatory.count_status}
									/>
								) : (
									<>
										<Chart
											title="Gráfico do relatório"
											status={contentRelatory.count_status}
										/>
									</>
								)}
							</div>
						</div>
						<div className={`${bootstrapResolution.filter} col-lg-6 col-md-6`} style={{marginTop: '0px!important'}}>
							<>
								<div className={`card ${'card-xl-stretch mb-xl-8'}`}>
									<div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
										<div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
											<div className='me-2'>
												<span className='fw-bolder text-gray-800 d-block fs-3'>Filtros</span>
												<span className='text-gray-400 fw-bold'>Filtre todos atendimentos</span>
											</div>
											<div className='mixed-widget-10-chart' style={{ width: '100%' }}>
												<div style={{ padding: '0px' }}>
													<div className='col-xl-12' style={{ padding: '0px' }}>
														<div className='col-xl-12' style={{ display: 'contents' }}>
															<div class="form-group" onClick={e => { openDatePicker('start_date') }}>
																<label for="start_date">Data de início</label>
																<input max={new Date().toISOString().split("T")[0]} onChange={e => setDateTime(prevState => ({ ...prevState, start: e.target.value }))} value={dateTime?.start ? dateTime.start : formatDate1Week()} id="start_date" type="date" class="form-control" aria-describedby="emailHelp" />
															</div>
															<div class="form-group" onClick={e => { openDatePicker('end_date') }}>
																<label for="end_date">Data de fim</label>
																<input max={new Date().toISOString().split("T")[0]} onChange={e => setDateTime(prevState => ({ ...prevState, end: e.target.value }))} value={dateTime?.end ? dateTime.end : new Date().toISOString().split("T")[0]} id="end_date" type="date" class="form-control" aria-describedby="emailHelp" />
															</div>
														</div>
														<div class="form-group" style={{ marginBottom: '10px' }}>
															<label for="status_tickets">Status</label>
															<select class="form-control" style={{ height: '45px' }} aria-describedby="statusHelp" id="status_tickets">
																<option selected value="" disabled>Selecione um status</option>
																<option value="">Todos</option>
																<option value="open">Atendendo</option>
																<option value="pending">Aguardando</option>
																<option value="closed">Finalizados</option>
															</select>
														</div>

														<div class="form-group" style={{ marginBottom: '10px' }}>
															<label for="user_tickets">Usuários</label>
															<select class="form-control" style={{ height: '45px' }} aria-describedby="statusHelp" id="user_tickets">
																<option selected value="" disabled>Selecione um usuário</option>
																<option value="">Todos</option>
																<option value="0">Sem usuário</option>
																<option value="chatbot">ChatBot</option>
																{usersRelatory.map(user => {
																	return <><option value={user.id}>{user.name}</option></>
																})}
															</select>
														</div>
														<div class="form-group" style={{ marginBottom: '22px' }}>
															<label for="user_tickets">Pesquisa</label>
															<input type="text" id="filter_tickets" placeholder="Contato ou número" class="form-control" aria-describedby="emailHelp" />
														</div>
														<Button variant="contained" fullWidth className={classes.button} onClick={filterData} >Aplicar Filtro</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						</div>
						<div>
							{loadingContent ? (
								<loadingTableRelatory
									title="Relatório"
									className='card-xl-stretch mb-xl-8'
									chartColor='info'
								/>
							) : (
								(contentRelatory?.all_tickets &&
									<>
										<Table
											title="Histórico de mensagens"
											subtitle="Veja o histórico do período filtrado"
											messagesRelatory={contentRelatory.all_tickets}
										/>
									</>
								)
							)}
						</div>
					</>
				)}
			</div>
		</MainContainer >
	);
};

export default RelatoryTicket;
