import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import api from "../../services/api";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useContext } from "react";
import socket from "../../hooks/useSocket";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    skeleton: {
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
}));

const reducer = (state, action) => {
    const { type, payload } = action;

    if (type === "LOAD_SCHEDULE_MESSAGES") {
        const scheduleMessages = payload;
        if (!Array.isArray(scheduleMessages)) return state; // Verifica se scheduleMessages é um array
        const newScheduleMessages = [];
        state = [];
        scheduleMessages.forEach((scheduleMessage) => {
            const scheduleMessageIndex = state.findIndex((c) => c.contactId === scheduleMessage.contactId);
            if (scheduleMessageIndex !== -1) {
                state[scheduleMessageIndex] = scheduleMessage;
            } else {
                newScheduleMessages.push(scheduleMessage);
            }
        });
        return [...state, ...newScheduleMessages];
    }

    if (type === "UPDATE_SCHEDULE_MESSAGES") {
        const scheduleMessage = payload;

        const scheduleMessageIndex = state.findIndex((c) => c.contactId === scheduleMessage.contactId);
        if (scheduleMessageIndex !== -1) {
            state[scheduleMessageIndex] = scheduleMessage;
            return [...state];
        } else {
            return [scheduleMessage, ...state];
        }
    }

    if (type === "DELETE_SCHEDULE_MESSAGE") {
        const scheduleMessageId = payload;
        const scheduleMessageIndex = state.findIndex((c) => c.contactId === scheduleMessageId);
        if (scheduleMessageIndex !== -1) {
            state.splice(scheduleMessageIndex, 1);
        }
        return [...state];
    }

    if (type === "RESET") {
        return [];
    }

    return state;
};

const ScheduleMessagesList = () => {
    const classes = useStyles();
    const history = useHistory();
    const [scheduleMessags, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchScheduleMessages = async () => {
            try {
                const { data } = await api.get("/scheduleMessages");
                if (Array.isArray(data.scheduleMessages)) { // Verifica se o payload é um array válido
                    dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: data.scheduleMessages });
                } else {
                    console.error("Formato inesperado de dados:", data);
                }
            } catch (error) {
                console.error("Failed to fetch schedule messages", error);
            } finally {
                setLoading(false);
            }
        };

        fetchScheduleMessages();

        socket.on(`scheduleMessagesByContact-${user.adminId}`, (data) => {
            if (data.action === "create" || data.action === "update") {
                dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: data.scheduleMessages });
            }
        });

        return () => { };

    }, []);

    const handleSelectScheduleMessage = async (contactId) => {
         setTimeout(() => { history.push(`/tickets/schedule/${contactId}`) }, 200);
    };

    if (loading) {
        return (
            <List className={classes.root}>
                {[...Array(5)].map((_, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Skeleton variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <Skeleton variant="text" width="80%" height={10} />
                    </ListItem>
                ))}
            </List>
        );
    }

    return (
        <List className={classes.root}>
            {scheduleMessags.map((scheduleMessage) => (
                <ListItem key={scheduleMessage.contactId} button onClick={() => handleSelectScheduleMessage(scheduleMessage.contactId)}>
                    <ListItemAvatar>
                        <Avatar src={scheduleMessage.Contact.profilePicUrl} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={scheduleMessage.Contact.name}
                        // secondary={`Horário: ${scheduleMessage.time} - Recorrência: ${scheduleMessage.recurrence}`}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {`${scheduleMessage.count}`}
                                </Typography>
                                {` —  Agendamento(s)`}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default ScheduleMessagesList;
