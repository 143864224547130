import { Button, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import IntegrationModal from "./IntegrationModal";

const WebhookTable = ({ integrations }) => {
  const [IntegrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);

  // manipulando o modal
	const showIntegrationModalOpen = () => {
		setIntegrationModalOpen(true);
	};

	const hideIntegrationModalOpen = () => {
		setIntegrationModalOpen(false);
    setIntegrationId(null);
	};

  const handleDisableWebhook = async (integrationData) => {
    try {
      integrationData = { ...integrationData, status: !integrationData.status };
      await api.put(`/integrations/${integrationData.id}`, integrationData);
      toast.success(i18n.t(`integrations.webhook.form.validations.success`));
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteWebhook = async (integrationData) => {
    try {
      await api.delete(`/integrations/${integrationData.id}`);
      toast.success(i18n.t(`integrations.webhook.form.validations.deleted`));
    } catch (err) {
      toastError(err);
    }
  }

  const handleEditIntegrations = (integration) => {
    setIntegrationId(integration.id)
    setIntegrationModalOpen(true);
  };

  return (
    <>
      <Button
        style={{
          marginLeft: "1.5px",
          float: "right"
        }}
        variant="contained"
        color="primary"
        onClick={() => showIntegrationModalOpen()}
      >
        {i18n.t("integrations.webhook.buttons.add")}
      </Button>

      <TableContainer>
        <Table aria-label="tabela de integrações">
          <TableHead>
            <TableRow>
              <TableCell align="left">{i18n.t("integrations.webhook.table.name")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.webhook.table.url")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.webhook.table.status")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.webhook.table.method")}</TableCell>
              <TableCell align="right">{i18n.t("integrations.webhook.table.edit")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations && integrations.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell align="left">{row.url}</TableCell>
                <TableCell align="left">
                  <Switch
                    checked={row.status}
                    onChange={()=> handleDisableWebhook(row)}
                    color="primary"
                    name={`status${row.status}`}
                    key={`status${row.status}`}
                    inputProps={{ 'aria-label': 'handle status' }}
                  />
                </TableCell>
                <TableCell align="left">{row.method}</TableCell>
                <TableCell align="right">
                  <Button
                    title={i18n.t("buttons.edit")}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => handleEditIntegrations(row)}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </Button>
                  <Button
                    title={i18n.t("buttons.delete")}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    onClick={(e) => {
                      handleDeleteWebhook(row)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3'
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <IntegrationModal 
        open={IntegrationModalOpen}
				onClose={hideIntegrationModalOpen}
        type="webhook"
        integrationId={integrationId}
      />
    </>
  );
}

export default WebhookTable;