import { useContext, useEffect, useReducer, useState } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";

import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import ConfirmationModal from "../../components/ConfirmationModal";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import socket from "../../hooks/useSocket";
import { Button } from "@material-ui/core";

const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const quickAnswers = action.payload;
    const newQuickAnswers = [];

    quickAnswers.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = quickAnswer;
      } else {
        newQuickAnswers.push(quickAnswer);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = quickAnswer;
      return [...state];
    } else {
      return [quickAnswer, ...state];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const quickAnswerId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '395px',
    margin: '20px 10px!important',
    padding: '10px',
		background: '#fff',
		borderRadius: '10px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const QuickAnswers = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [quickAnswers, dispatch] = useReducer(reducer, []);
  const [selectedQuickAnswers, setSelectedQuickAnswers] = useState(null);
  const [quickAnswersModalOpen, setQuickAnswersModalOpen] = useState(false);
  const [deletingQuickAnswers, setDeletingQuickAnswers] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);
  const adminId = user.company.id;

  const [getUsers, setGetUsers] = useState([]);
  const [getCreatedBy, setSelectedUserFilter] = useState("");


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, getCreatedBy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/usersList/");
          setGetUsers(data)
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/quickAnswers/", {
            params: { searchParam, pageNumber, getCreatedBy },
          });
          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.quickAnswers });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, getCreatedBy]);

  useEffect(() => {
    socket.on(`quickAnswer-${user.adminId}`, (data) => {

      if (data.adminId != user.adminId || !data.adminId) return;
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_QUICK_ANSWERS",
          payload: +data.quickAnswerId,
        });
      }
    });

    return () => {};
  }, []);

  const handleChange = async (event) => {
    if (event.target.value === "") {
      setSelectedUserFilter("");
    } else {
      setSelectedUserFilter(event.target.value);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(true);
  };

  const handleCloseQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(false);
  };

  const handleEditQuickAnswers = (quickAnswer) => {
    setSelectedQuickAnswers(quickAnswer);
    setQuickAnswersModalOpen(true);
  };

  const handleDeleteQuickAnswers = async (quickAnswerId) => {
    try {
      await api.delete(`/quickAnswers/${quickAnswerId}`);
      toast.success(i18n.t("quickAnswers.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingQuickAnswers(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  function returnSvg(texto, mediaName = null, mediaUrl = null) {
    let fileName = mediaName || 'Arquivo';

    return <Tooltip
            arrow
            title={
              <Typography gutterBottom color="inherit">
                download
              </Typography>
            }
           >
            <Typography
              style={{display: "flex", margin: "0px", width: "fit-content", color: "#000"}}
              component="a"
              href={mediaUrl || "#"}
              download={mediaUrl ? true : false}
              target="_blank"
            >
                {texto ? texto + '/ ' : ''}{fileName} <InsertDriveFileIcon />
            </Typography>
          </Tooltip>;
  }
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingQuickAnswers &&
          `${i18n.t("quickAnswers.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}
      >
        {i18n.t("quickAnswers.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <QuickAnswersModal
        open={quickAnswersModalOpen}
        onClose={handleCloseQuickAnswersModal}
        aria-labelledby="form-dialog-title"
        quickAnswerId={selectedQuickAnswers && selectedQuickAnswers.id}
      ></QuickAnswersModal>
      <div class='row gy-5 gx-xl-8'
        className={classes.modalStyle}
        onScroll={handleScroll}>
        <div className='col-xl-15'>
          <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t("quickAnswers.title")}</span>
                {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
              </h3>
              <div
                className='card-toolbar '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <FormControl variant="outlined" size="small">
                  <Select
                    value={getCreatedBy}
                    displayEmpty
                    style={{
                      padding: '0',
                      margin: '4px 10px -2px 0px',
                      height: '24px',
                      width: '173px',
                      fontSize: '15px'
                    }}
                    inputProps={{
                      style: {
                        padding: 5
                      }
                    }}
                    onChange={e => handleChange(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}

                  >
                    <MenuItem value="" className="filter-select-item">
                      <em>{i18n.t("quickAnswers.filterDefault")}</em>
                    </MenuItem>
                    {getUsers.map((user, key) => (
                      <MenuItem dense key={key} value={user.id} className="filter-select-item">
                        <ListItemText primary={user.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  size="small"
                  style={{
                    marginLeft: "1.5px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenQuickAnswersModal}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  {i18n.t("quickAnswers.buttons.add")}
                </Button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>{i18n.t("quickAnswers.table.shortcut")}</th>
                      <th className='min-w-120px'>{i18n.t("quickAnswers.table.message")}</th>
                      <th className='min-w-120px'>{i18n.t("quickAnswers.table.creator")}</th>
                      <th className='min-w-100px text-end'>{i18n.t("quickAnswers.table.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {quickAnswers.map((quickAnswer) => (
                        <>
                          <tr >
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {quickAnswer.shortcut}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {quickAnswer.message && !quickAnswer.media && !quickAnswer.driveUrl ? quickAnswer.message
                                  : !quickAnswer.message && (quickAnswer.media || quickAnswer.driveUrl) ? returnSvg('',quickAnswer.mediaName, quickAnswer.driveUrl)
                                    : returnSvg(quickAnswer.message,quickAnswer.mediaName, quickAnswer.driveUrl)
                                }
                              </a>
                            </td>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {quickAnswer.userName}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>

                                <button
                                  title={i18n.t("buttons.edit")}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleEditQuickAnswers(quickAnswer)}
                                >
                                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </button>
                                <button
                                  title={i18n.t("buttons.delete")}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={(e) => {
                                    setConfirmModalOpen(true);
                                    setDeletingQuickAnswers(quickAnswer);
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}

                    </>
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default QuickAnswers;
