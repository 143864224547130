import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    IconButton,
    Chip,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Grid,
    Divider,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import api from "../../services/api";
import RepeatOnIcon from '@material-ui/icons/Repeat';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import toastError from "../../errors/toastError";
import DividerWithText from "../DividerWithText";
import { toast } from "react-toastify";
const axios = require('axios');

const downloadMedia = async (url) => {
    try {
        const response = await axios.get(url, { responseType: 'blob' });
        const blob = response.data;
        const urlParts = url.split('/');
        const fileName = urlParts[urlParts.length - 1];
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    } catch (error) {
        console.error("Erro ao baixar a mídia:", error);
        return null;
    }
};

const CustomDialogTitle = styled(DialogTitle)({
    color: "#fff",
    textAlign: "left",
});

const days = [
    { label: "Domingo", key: "sunday" },
    { label: "Segunda", key: "monday" },
    { label: "Terça", key: "tuesday" },
    { label: "Quarta", key: "wednesday" },
    { label: "Quinta", key: "thursday" },
    { label: "Sexta", key: "friday" },
    { label: "Sábado", key: "saturday" },
];

const formatTime = (date, minutesToAdd = 10) => {
    // Adiciona os minutos ao objeto Date e ajusta automaticamente horas e minutos
    date.setMinutes(date.getMinutes() + minutesToAdd);

    // Formata as horas e minutos com padding para garantir dois dígitos
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
};

const formatDate = (date) => {
    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return date;
    }

    if (date instanceof Date) {
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        return localDate.toISOString().split('T')[0];
    }

    toastError('Invalid date format. Please provide a Date object or a valid date string.');
};

const formatDateForInput = (dateString) => {
    if (!dateString) return ''; // Retorna string vazia se não houver data
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Retorna a parte da data no formato YYYY-MM-DD
};

const initialStateWeekDays = {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
};


const ScheduleMessageModal = ({ scheduleId = null, ticketId = null, contactId = null, whatsappId = null, open, onClose }) => {

    const [sendAt, setSendAt] = useState(formatDate(new Date()));
    const [stopAt, setStopAt] = useState(sendAt || formatDate(new Date()));
    const [time, setTime] = useState(formatTime(new Date(), 10));
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [recurrence, setRecurrence] = useState('ONCE');
    const [numberOfRecurrence, setNumberOfRecurrence] = useState(1);
    const [finishOn, setFinishOn] = useState('never');
    const [useRecurrence, setUseRecurrence] = useState(false);
    const [contact, setContact] = useState(null);
    const [sendDays, setSendDays] = useState(initialStateWeekDays);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setSendAt(formatDate(new Date()));
        setStopAt(formatDate(new Date()));
        setTime(formatTime(new Date(), 10));
        setMessage("");
        setFile(null);
        setRecurrence('ONCE');
        setNumberOfRecurrence(1);
        setFinishOn('never');
        setUseRecurrence(false);
        setContact(null);
        setSendDays(initialStateWeekDays);
        onClose(false);
    };

    const getContactData = async (contactId) => {
        try {
            const { data } = await api.get(`/contacts/${contactId}`);
            setContact(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (contactId) {
            getContactData(contactId)
        }
    }, [contactId]);

    useEffect(() => {
        if (scheduleId) {
            (async () => {
                try {
                    const { data } = await api.get(`/scheduleMessages/${scheduleId}`);
                    getContactData(data.Contact.id)
                    setSendAt(formatDateForInput(data.sendAt))
                    setStopAt(data?.stopAt ? formatDateForInput(data.stopAt) : sendAt)
                    setMessage(data.message)
                    setTime(data.time)
                    setRecurrence(data.recurrence)
                    setNumberOfRecurrence(data.numberOfRecurrence)
                    setFinishOn(data.finishOn)
                    setUseRecurrence(recurrence != "ONCE")
                    setSendDays(JSON.parse(data.sendDays))
                    if (data?.mediaUrl) {
                        const media = await downloadMedia(data.mediaUrl);
                        setFile(media)
                    }
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }, [scheduleId, open]);

    useEffect(() => {
        const currentDay = new Date().getDay();
        const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        setSendDays((prevDays) => ({
            ...prevDays,
            [dayMap[currentDay]]: true,
        }));
    }, []);

    const handleChangeFinishOn = (event) => {
        setFinishOn(event.target.value);
    };

    const handleChangeRecurrence = (event) => {
        setRecurrence(event.target.value);
    };

    const handleDayChange = (day) => {
        setSendDays((prevState) => ({
            ...prevState,
            [day]: !prevState[day],
        }));
    };

    const handleChangeUseRecurrence = (event) => {
        setUseRecurrence(event.target.checked);
    };

    useEffect(() => {
        if (open) {
            const now = new Date();
            setSendAt(formatDate(now));
            setTime(formatTime(now));
        }
    }, [open]);

    const formatDateToBrazilian = (date) => {
        const [year, month, day] = date.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}T12:00:00`);

        if (isNaN(formattedDate)) {
            throw new Error("Data inválida");
        }

        const localDate = new Date(formattedDate.getTime() - (formattedDate.getTimezoneOffset() * 60000));

        const dayFormatted = String(localDate.getDate()).padStart(2, '0');
        const monthFormatted = String(localDate.getMonth() + 1).padStart(2, '0');
        const yearFormatted = localDate.getFullYear();

        return `${monthFormatted}/${dayFormatted}/${yearFormatted} 00:00:00`;
    };

    function isTimeValid(sendAt, time) {
        const currentDate = new Date();
        const completeDate = new Date(`${sendAt}T${time}`);
        const tenMinutesLater = new Date(currentDate.getTime() + 5 * 60 * 1000);
        if (sendAt === currentDate.toISOString().split('T')[0]) {
            return completeDate >= tenMinutesLater;
        };
        return true;
    }

    const handleConfirm = async () => {
        
        if (!sendAt || !time) return toast.error("Data ou hora não preenchidos");

        if (!isTimeValid(sendAt, time)) return toast.error("O horário de agendamento deve ser definido com pelo menos 5 minutos de antecedência.");

        if (!message && !file) return toast.error("É necessário uma mensagem ou um arquivo");

        setLoading(true);

        const formData = new FormData();

        if (file) {
            formData.append("medias", file);
        } else {
            formData.append("deleteMedia", true);
        };

        if (finishOn === 'date') {
            formData.append("stopAt", formatDateToBrazilian(stopAt));
        };

        if (ticketId) {
            formData.append("ticketId", ticketId);
        };

        if (whatsappId) {
            formData.append("connectionId", whatsappId);
        };

        if (contactId) {
            formData.append("contactId", contactId);
        };

        if (useRecurrence) {
            formData.append("recurrence", recurrence);
            formData.append("numberOfRecurrence", numberOfRecurrence);
        };

        formData.append("finishOn", finishOn);
        formData.append("body", message || '');
        formData.append("sendAt", formatDateToBrazilian(sendAt));
        formData.append("time", time);
        formData.append("sendDays", JSON.stringify(sendDays));

        console.log([...formData.entries()]); // Log para verificar o conteúdo do formData

        try {

            let data;
            if (scheduleId) {
                ({ data } = await api.post(`/scheduleMessages/u/${scheduleId}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }));
            } else {
                ({ data } = await api.post(`/scheduleMessages/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }));
            }
            setLoading(false);
            handleClose();

        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileRemove = () => {
        setFile(null);
    };

    return (
        <Dialog open={open} onClose={() => handleClose()} maxWidth="sm" fullWidth scroll="paper" aria-labelledby="schedules-dialog" disableEscapeKeyDown={true} disableBackdropClick={true}>
            <CustomDialogTitle id="schedules-dialog">{contact?.name ? `Agendamento para ${contact.name}` : `Agendar Mensagem`}</CustomDialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={!!scheduleId}
                            label={recurrence ? "Data inicial" : "Data"}
                            type="date"
                            value={sendAt}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => { setSendAt(newValue.target.value) }}
                            inputProps={{
                                min: formatDate(new Date()),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Horário"
                            disabled={!!scheduleId}
                            defaultValue={time}
                            onChange={(newValue) => setTime(newValue.target.value)}
                            ampm={false}
                            type="time"
                            inputProps={{
                                step: 300,
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center', padding: '6px 0px' }}>
                            <Checkbox
                                disabled={!!scheduleId}
                                checked={useRecurrence}
                                onChange={handleChangeUseRecurrence}
                                icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                                checkedIcon={<RepeatOnIcon style={{ backgroundColor: '#0b708c', color: '#fff' }} fontSize="small" />}
                            />
                            <Typography>Customizar recorrência de envio do agendamento</Typography>
                        </Box>
                    </Grid>
                    {useRecurrence && (
                        <>
                            <Grid item xs={12}>
                                <DividerWithText>
                                    <Chip
                                        label="Configurações de recorrência de envio" size="small"
                                        style={{ borderRadius: '3px' }}
                                    />
                                </DividerWithText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography>Repetir:</Typography>
                                <FormControl style={{ width: '100%', marginTop: '7px' }} size="small">
                                    <Select
                                        disabled={!!scheduleId}
                                        labelId="recurrence-select"
                                        id="recurrence-select"
                                        value={recurrence}
                                        onChange={handleChangeRecurrence}
                                    >
                                        <MenuItem value={'ONCE'}>Não repetir</MenuItem>
                                        <MenuItem value={'DAILY'}>Diariamente</MenuItem>
                                        <MenuItem value={'WEEKLY'}>Semanalmente</MenuItem>
                                        <MenuItem value={'MONTHLY'}>Mensalmente</MenuItem>
                                        <MenuItem value={'ANNUALLY'}>Anualmente</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography>Finalizar após:</Typography>
                                <FormControl style={{ width: '100%', marginTop: '7px' }} size="small">
                                    <Select
                                        disabled={recurrence === 'ONCE' || !!scheduleId}
                                        labelId="frequency-select"
                                        id="frequency-select"
                                        value={finishOn}
                                        onChange={handleChangeFinishOn}
                                    >
                                        <MenuItem value={'never'}>Nunca</MenuItem>
                                        <MenuItem value={'date'}>Na data</MenuItem>
                                        <MenuItem value={'recurrence'}>Recorrências</MenuItem>
                                    </Select>
                                </FormControl>
                                {finishOn === 'date' && (
                                    <TextField
                                        disabled={!!scheduleId}
                                        type="date"
                                        value={stopAt}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ marginTop: '12px' }}
                                        format="dd/MM/yyyy"
                                        onChange={(newValue) => setStopAt(newValue.target.value)}
                                        inputProps={{
                                            min: formatDate(sendAt),
                                        }}
                                    />
                                )}
                                {finishOn === 'recurrence' && (
                                    <TextField
                                        disabled={!!scheduleId}
                                        type="number"
                                        size="small"
                                        InputProps={{
                                            inputProps: { min: 0, max: 10 },
                                        }}
                                        value={numberOfRecurrence}
                                        onChange={e => setNumberOfRecurrence(e.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {(recurrence === "DAILY" || recurrence === "WEEKLY") && (
                                    <>
                                        <Typography>Repetir toda:</Typography>
                                        <Box style={{ display: 'flex' }}>
                                            {days.map((day) => (
                                                <Tooltip key={day.key} title={day.label}>
                                                    <Checkbox
                                                        disabled={!!scheduleId}
                                                        icon={<Chip label={day.label.charAt(0)} size="small" />}
                                                        checkedIcon={<Chip label={day.label.charAt(0)} size="small" style={{ backgroundColor: '#0b708c' }} />}
                                                        checked={sendDays[day.key]}
                                                        onChange={() => handleDayChange(day.key)}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            label="Mensagem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            fullWidth
                            margin="dense"
                            multiline
                            rows={4}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                startIcon={<PublishIcon />}
                                style={{ marginRight: 2 }}
                                size="small"
                            >
                                Upload Arquivo
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                            {file && (
                                <Box style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body2">{file.name}</Typography>
                                    <IconButton onClick={handleFileRemove} size="small">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} disabled={loading} style={{ backgroundColor: "#ff4747", color: "#ffffff" }} variant="contained" size="small">
                    Cancelar
                </Button>
                <Button onClick={handleConfirm} disabled={loading} color="primary" variant="contained" size="small">
                    {
                        loading ? 
                        (<CircularProgress
                            size={20}
                            color="#fff"
                        />)
                        : 
                        'Confirmar'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleMessageModal;
