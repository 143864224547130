import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import InputBase from '@material-ui/core/InputBase';
import Link from "@material-ui/core/Link";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";


const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 0,
		flexShrink: 0,
		transitionDuration: "0.3s"
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	motivoInput: {
		height: "40px",
		width: "230px",
		borderRadius: "8px",
		borderColor: "#dfdfdf"
	},
	motivoBotao: {
		width: "100%"
	}
}));

const BootstrapInput = withStyles(theme => ({
	root: { 'label + &': {} },
	input: {
		width: '195px',
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket }) => {

	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedConclusionMessage, setSelectedConclusionMessage] = useState({ id: null, message: '' });
	const [conclusionMessage, setConclusionMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const [modalOpen, setModalOpen] = useState(false);

	const handleUpdateTicketStatus = async (e, status) => {
		if (!selectedConclusionMessage?.id) {
			toastError({ response: { data: { error: "ERR_CONCLUDE_FOUND" } } });
			return;
		}

		var input = document.getElementById("motivo_input")
		var reason = input.value

		setLoading(true);
		try {			

			if (selectedConclusionMessage?.message && ticket?.whatsapp?.status && String(ticket.whatsapp.status).toLowerCase() == 'connected') await sendFlashMessage(selectedConclusionMessage.message);

			setTimeout(async () => {
				await api.put(`/tickets/${ticket.id}`, {
					status: "closed",
					reason: reason,
					conclusionId: selectedConclusionMessage.id ? selectedConclusionMessage.id : null,
				});
				if (status === "open") {
					 history.push(`/tickets/${ticket.id}`);
				} else {
					history.push("/tickets");
				}
				setLoading(false);
				toast.success(i18n.t("Atendimento finalizado!"));
			}, 500);

		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	};

	async function sendFlashMessage(data) {
		if (user?.signature && user?.name) data = `*${user.name.trim()}:*\n` + data.trim();

		const message = {
			read: 1,
			fromMe: true,
			mediaInBody: "",
			body: data,
			cleanMsg: data.trim(),
			quotedMsg: null,
			awaitSendMessage: true,
			moduleId: ticket.whatsapp.moduleId
		};

		await sendMesseger(message)
	};
	async function sendMesseger(messageData) {
		try {
			await api.post(`/messages/${ticket.id}`, messageData);
		} catch (err) {
			toastError(err);
		}
	}


	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchConclusionMessage = async () => {
				try {
					const { data } = await api.get("/conclusionMessagesAll/");
					setConclusionMessage(data.conclusionMessages);
				} catch (err) {
					toastError(err);
				}
			};
			fetchConclusionMessage();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [open]);

	if (open) {
		if (window.innerWidth > 560) {
			document.querySelector("#ModalConcluir").style = "width: 320px !important;"
		}
	}

	function modalConcludeClose(e) {
		document.querySelector("#ModalConcluir").style = "width: 0px !important;"
	}




	useEffect(() => {
		if (open) {
			document.addEventListener("mouseup", function (e) {
				var obj = document.getElementById("ModalConcluir");
				if (obj) {
					var tag = e.target.tagName;
					var clickedElement = (obj.contains(e.target) || e.target.id === `ModalConcluir` || (tag === 'LI' || tag === 'SPAN' || tag === 'UL' || tag === 'DIV'));
					if (clickedElement === false) {
						handleDrawerClose(e);
						modalConcludeClose(e);
						setSelectedConclusionMessage({ id: null, message: '' });
					}
				}
			});
		}
	}, [open]);

	const handleConclusionMessage = (id) => {
		let find = conclusionMessage.find(cm => cm.id === id)
		setSelectedConclusionMessage({ id: find.id, message: find.message })
	}

	return (
		<Drawer
			className={classes.drawer}
			id="ModalConcluir"
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton
					id="closeModalConcluir"
					onClick={(e) => {
						handleDrawerClose(e);
						modalConcludeClose(e);
						setSelectedConclusionMessage({ id: null, message: '' });
						document.querySelector('[id="motivo_input"]').value = '';
					}
					}>

					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.headerConclude")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<Avatar
							alt={contact.name}
							src={contact.profilePicUrl}
							className={classes.contactAvatar}
						></Avatar>
						<Typography>{contact.name}</Typography>
						<Typography>
							<Link href={`https://api.whatsapp.com/send?phone=${contact.number}`}>{contact.number}</Link>
						</Typography>

						{/*<TextField label="Motivo da Finalização" variant="outlined" id="motivo_input" />*/}

					</Paper>
					<Paper square variant="outlined" className={classes.contactDetails} style={{ marginTop: '10px' }}>
						<DialogTitle id="form-dialog-title">
							Finalização
						</DialogTitle>
						<DialogContent dividers>
							<label>
								Selecione uma finalização
								<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
							</label>
							<Select
								value={selectedConclusionMessage?.id}
								onChange={e => handleConclusionMessage(e.target.value)}
								input={<BootstrapInput />}
								required
							>
								<MenuItem disabled value="">
									Selecione uma mensagem
								</MenuItem>
								{conclusionMessage?.map((msg, index) => (
									<MenuItem key={index} title={msg.message} value={msg.id}>
										{msg.shortcut}
									</MenuItem>
								))}
							</Select>
						</DialogContent>
						<DialogContent dividers>
							<label>Descreva o motivo da finalização:</label>
							<input
								id="motivo_input"
								style={{
									borderColor: '#c4c4c4',
									borderRadius: '3px',
									width: '100%',
									height: '45px'
								}}
								type="text"
								className='form-control'
								name="finishReason"

							/>
						</DialogContent>
						<DialogContent dividers>
							<Button
								className={classes.motivoBotao}
								variant="contained"
								color="primary"
								onClick={e => handleUpdateTicketStatus(e, "closed")}
							>
								{i18n.t("messagesList.header.buttons.conclude")}
							</Button>
						</DialogContent>
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
