import React, { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { format, parseISO } from "date-fns";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import api from "../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import Skeleton from '@material-ui/lab/Skeleton';
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ScheduleHeader from "../ScheduleHeader";
import ScheduleMessageModal from "../ScheduleMessageModal";
import ConfirmationModal from "../ConfirmationModal";
import { i18n } from "../../translate/i18n";
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import socket from "../../hooks/useSocket";

function truncateText(text, maxLength) {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    overflowY: 'auto',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  skeletonCell: {
    padding: '30px',
  },
  headerCell: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold',
  },
}));

const sortMessages = (messages) => {
  return messages.sort((a, b) => {
    const dateA = new Date(a.sendAt);
    const dateB = new Date(b.sendAt);

    // Primeiro ordena pela data "sendAt" em ordem decrescente
    if (dateA.getTime() !== dateB.getTime()) {
      return dateB.getTime() - dateA.getTime();
    }

    // Se as datas forem iguais, ordena pelo "time" no formato mm:ss em ordem decrescente
    const [minA, secA] = a.time.split(":").map(Number);
    const [minB, secB] = b.time.split(":").map(Number);

    const totalSecondsA = minA * 60 + secA;
    const totalSecondsB = minB * 60 + secB;

    return totalSecondsB - totalSecondsA;
  });
};

const reducer = (state, action) => {
  const { type, payload } = action;

  if (type === "LOAD_SCHEDULE_MESSAGES") {
    const scheduleMessages = payload;
    if (!Array.isArray(scheduleMessages)) return state;
    const newScheduleMessages = [];
    state = [];
    scheduleMessages.forEach((scheduleMessage) => {
      const scheduleMessageIndex = state.findIndex((c) => c.id === scheduleMessage.id);
      if (scheduleMessageIndex !== -1) {
        state[scheduleMessageIndex] = scheduleMessage;
      } else {
        newScheduleMessages.push(scheduleMessage);
      }
    });
    return [...state, ...newScheduleMessages];
  }

  if (type === "UPDATE_SCHEDULE_MESSAGES") {
    const scheduleMessage = payload;
    const scheduleMessageIndex = state.findIndex((c) => c.id === scheduleMessage.id);
    if (scheduleMessageIndex !== -1) {
      state[scheduleMessageIndex] = scheduleMessage;
      state = sortMessages(state);
      return [...state];
    } else {
      return sortMessages([scheduleMessage, ...state]);
    }
  }

  if (type === "DELETE_SCHEDULE_MESSAGE") {
    const scheduleMessageId = payload;
    const scheduleMessageIndex = state.findIndex((c) => c.id == scheduleMessageId);
    if (scheduleMessageIndex !== -1) {
      state.splice(scheduleMessageIndex, 1);
    }
    return [...state];
  }

  if (type === "RESET") {
    return [];
  }

  return state;
};

const Schedule = () => {
  const { contactId } = useParams();
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [data, dispatch] = useReducer(reducer, []);
  const [contact, setContact] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [openEditScheduleModal, setOpenEditScheduleModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [state, setState] = useState({ status: 'await' });

  useEffect(() => {
    const fetchData = async () => {
      if (contactId) {
        try {
          setLoadingTable(true);
          const { data } = await api.get(`/scheduleMessages/list/${contactId}`, {
            params: {
              pageNumber: page + 1,
              limit: rowsPerPage,
              searchParam: searchTerm,
              status: state.status
            },
          });
          dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: data.scheduleMessage });
          setCount(data.count);
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        } finally {
          setLoadingTable(false);
        }
      }
    };

    fetchData();
  }, [contactId, page, rowsPerPage, searchTerm, state]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: contactData } = await api.get(`/contacts/${contactId}`);
        setContact(contactData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false);
      }
    })();
  }, [contactId]);

  useEffect(() => {
    socket.on(`scheduleMessages-${user.adminId}`, (data) => {
      if (data.action === "create" || data.action === "update") {
        // se o status for diferente do selecionado, remover da tela
        if (data.schedule.status != state.status) dispatch({ type: "DELETE_SCHEDULE_MESSAGE", payload: data.schedule.id });
        else dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: data.schedule });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE_MESSAGE", payload: data.scheduleId });
      }
    });

    return () => { };

  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = async (id) => {
    console.log("Edit schedule message with id:", id);
    setScheduleId(id);
    setTimeout(() => {
      setOpenEditScheduleModal(true);
    }, 200);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/scheduleMessages/${id}`);
      setScheduleId(null);
    } catch (error) {
      console.error("Erro ao deletar mensagem:", error);
    }
  };

  const handleSendNow = async (id) => {
    try {
      const { data } = await api.post(`/scheduleMessages/send/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const createResponseToDate = (start, stop, time) => {
    if (stop) {
      return `${format(parseISO(start), "dd/MM/yyyy")} até ${format(parseISO(stop), "dd/MM/yyyy")} - ${time}`
    }
    if (start) {
      return `${format(parseISO(start), "dd/MM/yyyy")} - ${time}`
    }
    return ''
  }

  return (
    <Paper className={classes.root}>
      <ScheduleMessageModal
        contactId={contactId}
        open={openEditScheduleModal}
        onClose={() => { setScheduleId(null); setOpenEditScheduleModal(false); }}
        scheduleId={scheduleId}
      />
      <ConfirmationModal
        open={openConfirmModal}
        onClose={setOpenConfirmModal}
        title="Tem certeza que deseja excluir este agendamento?"
        children={"Esta mensagem agendada será excluida completamente."}
        onConfirm={() => handleDelete(scheduleId)}

      />
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <ScheduleHeader
          contact={contact}
          total={count}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          state={state}
          setState={setState}
          openNewSchedule={() => setOpenEditScheduleModal(true)}
        />
      )}
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>ID</TableCell>
              <TableCell className={classes.headerCell}>{i18n.t("schedules.table.columns.message")}</TableCell>
              <TableCell className={classes.headerCell}>{i18n.t("schedules.table.columns.status")}</TableCell>
              <TableCell className={classes.headerCell}>{i18n.t("schedules.table.columns.date")}</TableCell>
              <TableCell className={classes.headerCell}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingTable ? (
              [1, 2, 3, 4].map((skeletonIndex) => (
                <TableRow key={skeletonIndex}>
                  <TableCell className={classes.skeletonCell}><Skeleton variant="text" /></TableCell>
                  <TableCell className={classes.skeletonCell}><Skeleton variant="text" /></TableCell>
                  <TableCell className={classes.skeletonCell}><Skeleton variant="text" /></TableCell>
                  <TableCell className={classes.skeletonCell}><Skeleton variant="text" /></TableCell>
                  <TableCell className={classes.skeletonCell}><Skeleton variant="text" /></TableCell>
                </TableRow>
              ))
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body2">{i18n.t("schedules.noData")}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              data.map((schedule) => (
                <TableRow key={schedule.id}>
                  <TableCell>{schedule.id}</TableCell>
                  <TableCell>{truncateText(schedule.message, 40)}</TableCell>
                  <TableCell>{i18n.t(`schedules.table.status.${schedule.status}`)}</TableCell>
                  <TableCell>
                    {createResponseToDate(schedule.sendAt, schedule.stopAt, schedule.time)}
                    <Tooltip 
                      title={
                        <Typography>{`${i18n.t('schedules.scheduleBy')} ${schedule?.User?.name} (${createResponseToDate(schedule.sendAt, schedule.stopAt, schedule.time)})`}</Typography>
                      }
                    >
                      <AlarmOutlinedIcon style={{ float: "right", color: "#616161" }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Editar">
                      <IconButton 
                        onClick={() => handleEdit(schedule.id)} 
                        color="primary"
                        disabled={['await', 'started'].includes(schedule.status) == false}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <IconButton 
                        onClick={() => {
                          setScheduleId(schedule.id);
                          setOpenConfirmModal(true);
                        }}
                        color="secondary"
                        disabled={['await', 'started'].includes(schedule.status) == false}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar agora">
                      <IconButton 
                        onClick={() => handleSendNow(schedule.id)}
                        color="default"
                        disabled={['await', 'started'].includes(schedule.status) == false}
                      >
                        <SendIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Schedule;
